<template>
    <div>
        <RegisterPersonComponent />
    </div>
</template>

<script>
import RegisterPersonComponent from "@/components/RegisterPersonComponent.vue"

export default {
    components: {
        RegisterPersonComponent
    },
}
</script>

<style lang="">

</style>
