<template>
    <div class="row justify-content-center align-items-center vh-100">
        <div class="col-12 col-md-10 col-lg-8 col-xxl-6">
            <div class="inner">
                <div class="text-center">
                    <h2 class="title">{{ $t("login.passwordChange") }}</h2>
                </div>
                <div class="col-12 mt-4">
                    <form @submit.prevent="confirmReset" class="">
                        <div class="input_group d-flex" :class="{ active: activeInput === 'password1' }">
                            <label for="password1" class="mx-4 my-auto">
                                {{ $t("login.newPassword") }}:
                            </label>
                            <input v-model="postData.newPassword1" class="form-control" type="password"
                                :placeholder="$t('login.newPassword')" required @focus="activateInput('password1')"
                                @blur="deactivateInput()" />
                        </div>
                        <div class="input_group d-flex mt-2" :class="{ active: activeInput === 'password2' }">
                            <label for="password2" class="mx-4 my-auto">
                                {{ $t("login.confirmPassword") }}:
                            </label>
                            <input v-model="postData.newPassword2" class="form-control" type="password"
                                :placeholder="$t('login.confirmPassword')" required @focus="activateInput('password2')"
                                @blur="deactivateInput()" />
                        </div>
                    </form>
                </div>
                <div class="col-12 text-center">
                    <div v-if="message" class="message mt-3">
                        <span>{{ message }}</span>
                    </div>
                    <div v-if="error" class="error mt-3">
                        <span>{{ error }}</span>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <button type="submit" class="btn btn_submit mt-3" @click="confirmReset()">
                    {{ $t("utils.submit") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        uid: String,
        token: String
    },
    data() {
        return {
            postData: {
                newPassword1: "",
                newPassword2: "",
            },
            message: false,
            error: false,
            activeInput: false
        }
    },
    methods: {
        confirmReset() {
            const formData = new FormData();
            formData.append('new_password1', this.postData.newPassword1);
            formData.append('new_password2', this.postData.newPassword2);

            // Ensure passwords match
            if (this.postData.newPassword1 !== this.postData.newPassword2) {
                this.message = "Passwords do not match.";
                return;
            }

            this.$store.getters.api.post(`/auth/password-reset-confirm/${this.uid}/${this.token}`, formData).then(response => {
                if (response.data.error_code == 303) {
                    this.error = (this.$t("error.303"));
                }
                else {
                    this.message = (this.$t("general.passwordChanged"));
                }
            }).catch(error => {
                if (error.response) {
                    this.error = error.response.data.detail;
                }
            });
        },
        activateInput(inputId) {
            this.activeInput = inputId;
        },
        deactivateInput() {
            // Clear the active input ID
            this.activeInput = null
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.inner {
    background: radial-gradient(circle, $light-purple 6%, $purple 100%);
    border-radius: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 45px 50px 90px 50px;
    margin: 0 0 20px 0;
}

.title {
    color: $white;
    font-size: 48px;
    text-shadow: 2px 2px 5px $black;
}

.input_group {
    background-color: $white;
    border-radius: 50px;

    label {
        color: $black;
        width: 40%;
        font-size: 20px;
    }

    input {
        background-color: $white;
        border: none;
        width: 100%;
        outline: none;
        border-radius: 50px;
        padding: 20px 20px;
        font-size: 20px;
    }
}

.input_group.active label {
    transition: all 0.2s ease-in-out;
    display: none
}

.input_group.active {
    background-color: $white;
    padding: 0
}

.input_group.active input {
    width: 100%;
    opacity: 1;
    padding: 20px 20px;
    border-radius: 50px;
    outline: none;
    border: none;
}

.error {
    color: $light-red;
    font-family: 'Rubik One Regular', sans-serif;
    font-size: 20px;
    background-color: $beige;
    border-radius: 35px;
    text-align: center;
    padding: 10px;
}

.message {
    color: $green;
    font-family: 'Rubik One Regular', sans-serif;
    font-size: 20px;
    background-color: $beige;
    border-radius: 35px;
    text-align: center;
    padding: 10px;
}
</style>