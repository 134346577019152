<template>
    <CompanyBoxContent v-if="company && company.vr && company.vr.exekuce" :title="$t('company.executions') + ':'">
        <template #data>
            <div class="col-12 box">
                <div v-for="execution in company.vr.exekuce" :key="execution.id" class="row mt-2">
                    <span>{{ execution.hodnota }}</span>
                    <div class="mt-3">
                        <span>{{ $t('company.dateOfRegistration') }}: </span>
                        <i18n-d v-if="execution.datumZapisu" tag="span"
                            :value="new Date(execution.datumZapisu)"></i18n-d>
                    </div>
                    <div v-if="execution.datumVymazu" class="mt-1">
                        <span>{{ $t('company.dateOfRemoval') }}: </span>
                        <i18n-d v-if="execution.datumVymazu" tag="span"
                            :value="new Date(execution.datumVymazu)"></i18n-d>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {

        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";
</style>