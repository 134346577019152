<template>
  <p class="px-2 my-0">
    <slot name="header"><span :class="{ 'editable_label': editable }">{{ header }}:&nbsp;</span></slot>
    <template v-if="editable">
      <slot name="editor">
        <input class="form-control editable_input mt-1" :type="inputType" v-model="model" />
      </slot>
    </template>

    <template v-else>
      <slot name="body">
        <span class="bold">{{ body ? body : "-" }}</span>
      </slot>
    </template>
  </p>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: false
    },
    body: {
      required: false
    },
    inputType: {
      type: String,
      required: false,
      default: "text"
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.body
      },
      set(newValue) {
        this.$emit('body', newValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.bold {
  font-weight: 500;
  font-family: 'Rubik One Regular', sans-serif;
}

.editable_input {
  border: 3px solid $dark-purple;
  border-radius: 35px;
  font-size: 18px;
  font-weight: 900;
}

.editable_label {
  font-size: 25px;
}
</style>
