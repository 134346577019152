<template>
    <div class="tag" :style="{ 'color': color, 'background-color': bg }">
        {{ text }}
        <button v-if="showDeleteButton" class="delete-button" @click="deleteTag">x</button>
    </div>
</template>


<script>
export default {
    props: {
        text: { type: String, required: true },
        color: { type: String, required: true },
        tagId: Number,
        bg: { type: String, required: true },
        showDeleteButton: {   // Whether to show the delete button or not
            type: Boolean,
            default: false
        }
    },
    methods: {
        deleteTag() {
            this.$emit('delete', this.tagId);
        }
    },
}
</script>


<style lang="scss">
.tag {
    background-color: grey;
    color: white;
    font-size: 14px;
    padding: 4px 8px;
    width: 150px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    position: relative;

    .delete-button {
        background-color: transparent;
        border: none;
        font-size: 20px;
        color: red;
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.3s ease;
        display: none;
    }

    &:hover .delete-button {
        display: inline-block;
        opacity: 1;
        /* Show on hover */
    }
}
</style>
