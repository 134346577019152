<template>
    <CompanyBoxContent v-if="company && company.web_page" :title="$t('company.website') + ':'">
        <template #data>
            <div class="row box">
                <span>{{ company.web_page.name }}</span>
                <span>{{ company.web_page.facebook }}</span>
                <span>{{ company.web_page.instagram }}</span>
                <span>{{ company.web_page.x }}</span>
                <span>{{ company.web_page.tiktok }}</span>
                <span v-for="phoneNumber in company.web_page.phone_numbers">{{ phoneNumber.phone_number }}</span>
                <span v-for="email in company.web_page.emails">{{ email.email }}</span>
            </div>
        </template>
    </CompanyBoxContent>
</template>


<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {

        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";
</style>