<template>
    <div class="bussiness_block">
        <div class="card shadow border-0">
            <div class="row my-4 px-5 align-items-center justify-content-center">
                <div class="col-12">
                    <h1 class="card-title text-center">
                        Zásady zpracování osobních údajů
                    </h1>
                </div>
                <!-- <div class="col-3">
                    <router-link class="btn btn_main" :to="{ name: 'BussinessTermsHistory' }">{{
                        $t("termsAndConditions.history") }}</router-link>
                </div> -->
            </div>
            <div class="card-body px-5">
                <!-- Put component with actual terms here -->
                <GDPR__1_0 />
            </div>
        </div>
    </div>
</template>

<script>
import GDPR__1_0 from "@/components/GDPRHistory/GDPR__1_0.vue";
export default {
    components: {
        GDPR__1_0
    }
};
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.bussiness_block {
    font-family: 'Arial', sans-serif;
    width: 70%;
    margin: 15px auto;
}

.card {
    border-radius: 35px;
    min-height: 100vh;
}

.list-group-item {
    border: 0;
    padding: 0.75rem 1.25rem;
}
</style>
