<template>
    <div>
        <RegisterInstitutionComponent />
    </div>
</template>

<script>
import RegisterInstitutionComponent from '../components/RegisterInstitutionComponent.vue';

export default {
    components: {
        RegisterInstitutionComponent
    }
}
</script>

<style lang="">

</style>