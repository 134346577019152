<template>
    <!-- Register person and add him to institution -->
    <div>
        <RegisterPersonComponent @user-added="handleUserAdded" />
    </div>
</template>

<script>
import RegisterPersonComponent from '@/components/RegisterPersonComponent.vue'
export default {
    components: {
        RegisterPersonComponent
    },
    methods: {
        handleUserAdded({ userId, token }) {
            this.addUserToInstitution(userId, token);
        },
        addUserToInstitution: function (userId, token) {
            this.$store.getters.api.post(`/institution/user/${token}/add`, { user_id: userId })
                .then((response) => {
                    this.$router.push({ name: "companies" });
                })
        }
    }
}
</script>

<style lang="">

</style>