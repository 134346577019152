<template>
    <CompanyBoxContent :title="$t('company.yourNotes') + ':'">
        <template #data>
            <div v-for="(note, index) in notHiddenNotes" :key="note.id" class="box">
                <template v-if="!editingNote || note.id !== editingNoteId">
                    <!-- Note -->
                    <div class="row align-items-center" :class="{ 'mt-2': index > 0 }">
                        <UserAvatar v-if="note" :user="note.user" />
                        <!-- Note content section -->
                        <div class="col-6 col-md-7 col-xxl-9">
                            <div class="row align-items-center">
                                <div class="col-12 col-xxl-8 full_width">
                                    <div class="row full_width">
                                        <div class="col-12 full_width">
                                            <span style="font-size: 12px;" class="me-2 fw-bold">
                                                {{ note.user.first_name }} {{ note.user.last_name }}
                                            </span>
                                            <i18n-d style="font-size: 12px;" v-if="note.created" tag="span"
                                                :value="new Date(note.created)"
                                                :format="{ dateStyle: 'long', timeStyle: 'medium' }"></i18n-d>
                                        </div>
                                        <div>
                                            <span>{{ note.note }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xxl-4">
                                    <!-- Note buttons -->
                                    <div class="d-flex justify-content-between mt-2">
                                        <button @click="editNote(note.id)" class="btn w-50 btn_edit me-2">
                                            <icon icon="Edit"></icon>
                                        </button>
                                        <button @click="hideNote(note.id)" class="btn w-50 btn_delete">
                                            <icon icon="Bin"></icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- Editing -->

                <template v-else>
                    <textarea class="form-control" v-model="editedNote" rows="2">{{ note.note }}</textarea>
                    <div class="d-flex justify-content-between">
                        <button @click="cancelEdit" class="btn w-50 btn_secondary me-2">{{ $t('utils.cancel')
                            }}</button>
                        <button @click="saveEditedNote" class="btn w-50 btn_main">{{ $t('utils.save') }}</button>
                    </div>
                </template>
            </div>
            <!-- Input field for adding new note -->
            <div class="mt-3 box note_box">
                <textarea class="form-control p-1" v-model="newNote" rows="2"
                    :placeholder="$t('general.writeNote')"></textarea>
            </div>
            <div class="mt-3 text-center d-flex justify-content-center">
                <button class="btn btn_main d-flex align-items-center" @click="addNote">
                    <icon icon="Plus" class="me-2"></icon>
                    <span>{{ $t('general.addNote') }}</span>
                </button>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue";
import UserAvatar from "@/components/CompanyDetail/UserAvatar.vue";

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
        UserAvatar
    },
    data() {
        return {
            newNote: "",
            editingNote: false,
            editingNoteId: null,
            editedNote: ""
        };
    },
    computed: {
        notHiddenNotes() {
            return this.company.notes ? this.company.notes.filter(note => !note.hidden) : [];
        }
    },
    methods: {
        addNote() {
            if (!this.newNote.trim()) {
                return; // Prevent adding empty notes
            }
            const formData = { newNote: this.newNote };

            this.$store.getters.api.post(`/company/${this.$route.params.slug}/note/add`, formData)
                .then(response => {
                    // Refresh company data after adding the note
                    this.$emit('reload');
                    this.newNote = '';
                })
                .catch(error => {
                    console.error('Error adding note:', error);
                });
        },
        editNote(noteId) {
            this.editingNote = true;
            this.editingNoteId = noteId;
            // Set initial value of editedNote to current note's text
            const note = this.company.notes.find(note => note.id === noteId);
            this.editedNote = note ? note.note : '';
        },
        cancelEdit() {
            // Reset editing state without saving changes
            this.editingNote = false;
            this.editingNoteId = null;
            this.editedNote = '';
        },
        saveEditedNote() {
            const formData = { editedNote: this.editedNote };

            this.$store.getters.api.put(`/company/${this.$route.params.slug}/note/${this.editingNoteId}/edit`, formData)
                .then(response => {
                    // Refresh company data after editing the note
                    this.$emit('reload');
                    // Reset editing state
                    this.editingNote = false;
                    this.editingNoteId = null;
                    this.editedNote = '';
                })
                .catch(error => {
                    console.error('Error editing note:', error);
                });
        },
        hideNote(noteId) {
            this.$store.getters.api.put(`/company/${this.$route.params.slug}/note/${noteId}/hide`)
                .then(response => {
                    // Refresh company data after hiding the note
                    this.$emit('reload');
                })
                .catch(error => {
                    console.error('Error hiding note:', error);
                });
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

textarea {
    border: none;
    resize: none;
}

textarea:active {
    border: none;
    resize: none;
}

.note_box {
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;
}

.btn_edit {
    color: $dark-purple;
    border: none;
    font-weight: 600;

    svg {
        stroke: $dark-purple;
        width: 30px;
        height: 30px;
    }
}

.btn_delete {
    color: $dark-purple;
    border: none;
    font-weight: 600;

    svg {
        stroke: $dark-purple;
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: 1700px) {
    .full_width {
        width: 100%;
    }
}
</style>