<template>
    <div class="text-center mt-2">
        <h3 class="title">{{ $t('institution.email.title') }}</h3>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    font-size: 40px;
    color: $white;
    font-weight: 500;
    font-family: 'Rubik One Regular', sans-serif;
}
</style>