<template lang="">
    <div class="bussiness_block">
        <div class="card shadow border-0">
            <div class="d-flex my-4 px-5">
                <div class="mx-auto">
                    <h1 class="card-title text-center">{{ $t("termsAndConditions.history") }}</h1>
                </div>
                <div class="ms-auto me-4">
                    <router-link class="btn btn_main" :to="{ name: 'BussinessTermsActual' }">{{
                        $t("termsAndConditions.actual") }}</router-link>
                </div>
            </div>
            <div class="card-body px-5">
                <div class="row">
                    <span v-if="show_version == null" @click="showVersion('1.0')" class="version">Obchodní podmínky platné od 24. dubna 2019</span>
                    <button v-else @click="this.show_version = null" class="btn btn_secondary w-25 mx-auto mb-3">
                        <span>
                            Zavrit
                        </span>
                    </button>
                </div>
                <div v-if="show_version == '1.0'">
                    <BussinessTerms__1_0 />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BussinessTerms__1_0 from "@/components/BussinessTermsHistory/BussinessTerms__1_0.vue";
export default {
    components: {
        BussinessTerms__1_0
    },
    data() {
        return {
            show_version: null
        }
    },
    methods: {
        showVersion(version) {
            this.show_version = version;
        }
    }
};
</script>
<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.bussiness_block {
    font-family: 'Arial', sans-serif;
    width: 70%;
    margin: 15px auto;
}
.card {
    min-height: 100vh;
    border-radius: 35px;
}
.version {
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    color: $purple;
}
</style>