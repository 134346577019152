<template>
    <CompanyBoxContent v-if="company && company.vr && company.vr.zakladniKapital"
        :title="$t('company.basicCapital') + ':'">
        <template #data>
            <div v-for="capital in company.vr.zakladniKapital" :key="capital.id" class="col-12 box mt-2">
                <div class="d-flex">
                    <span class="pe-1">{{ $t("company.value") }}:</span>
                    <span class="pe-1">{{ formatNumber(capital.vklad.hodnota) }} </span>
                    <span v-if="capital.vklad.typObnos == 'KORUNY'"> CZK</span>
                </div>
                <div class="d-flex">
                    <span class="pe-1">{{ $t("company.dateOfRegistration") }}:</span>
                    <i18n-d v-if="capital.datumZapisu" tag="span" :value="new Date(capital.datumZapisu)"></i18n-d>
                </div>
            </div>
            <div v-if="company.vr.akcie" class="col-12 mt-2 box">
                <div v-for="stock in company.vr.akcie" :key="stock.id" class="row">
                    <div class="d-flex">
                        <span class="pe-1">{{ $t("company.stockValue") }}:</span>
                        <span class="pe-1"> {{ formatNumber(stock.hodnota.hodnota) }},-</span>
                        <span v-if="stock.hodnota.typObnos == 'KORUNY'"> CZK</span>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {

        }
    },
    methods: {
        formatNumber(value) {
            const numericValue = parseFloat(value.replace(';', '.'));
            return numericValue.toLocaleString('cs-CZ');
        },

    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";
</style>