<template>
    <div class="spinner-box">
        <div class="spinner"></div>
        <div class="counter-spinner"></div>
    </div>
</template>

<script>
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.spinner-box {
    position: relative;
    width: 120px;
    height: 120px;
}

.spinner {
    position: absolute;
    top: 20px;
    left: 20px;
    border: 8px solid transparent;
    border-top: 8px solid $dark-purple;
    border-left: 8px solid $dark-purple;
    border-bottom: 8px solid $dark-purple;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
}

.counter-spinner {
    position: absolute;
    top: 0px;
    left: 0px;
    border: 8px solid transparent;
    border-top: 8px solid $dark-purple;
    border-left: 8px solid $dark-purple;
    border-bottom: 8px solid $dark-purple;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: counter-spin 1.75s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes counter-spin {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
</style>
