<template>
    <div v-if="$store.getters.isAdmin" class="institution row">
        <!-- USERS -->
        <div class="mt-3 block">
            <InstitutionUsers :institutionId="institutionId" />
        </div>
        <!-- TAGS -->
        <div class="mt-4 block">
            <InstitutionTags :institutionId="institutionId" />
        </div>
        <!-- New companies email settings -->
        <!-- <div class="mt-4 block">
            <InstitutionNewCompanies :institutionId="institutionId" />
        </div> -->
        <!-- INVOICING -->
        <div class="mt-4 block">
            <InstitutionInvoicing :institutionId="institutionId" />
        </div>
    </div>
</template>

<script>
import InstitutionInvoicing from '@/components/Institution/InstitutionInvoicing.vue';
import InstitutionUsers from '@/components/Institution/InstitutionUsers.vue';
import InstitutionTags from '@/components/Institution/InstitutionTags.vue';
import InstitutionNewCompanies from '@/components/Institution/InstitutionNewCompanies.vue';

export default {
    components: {
        InstitutionInvoicing,
        InstitutionUsers,
        InstitutionTags,
        InstitutionNewCompanies
    },
    data() {
        return {
            institutionId: null
        }
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.institutionId = this.$store.getters.selectedInstitution;
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";
</style>