<template>
    <div class="row justify-content-center align-items-center vh-100">
        <div class="col-12 col-md-10 col-lg-8 col-xxl-6">
            <div class="inner">
                <div class="text-center">
                    <h2 class="title">{{ $t("login.passwordReset") }}</h2>
                </div>
                <div class="col-12 mt-4">
                    <form @submit.prevent="submitReset" class="">
                        <div class="input_group d-flex" :class="['input_group', { active: inputIsActive }]">
                            <label for="email" class="mx-4 my-auto">
                                {{ $t("login.email") }}:
                            </label>
                            <input v-model="postData.email" class="form-control" type="email"
                                :placeholder="$t('login.yourEmail')" required @focus="activateInput()"
                                @blur="deactivateInput()" />
                        </div>
                    </form>
                </div>
                <div class="col-12 text-center">
                    <div v-if="message" class="message mt-3">
                        <span>{{ message }}</span>
                    </div>
                    <div v-if="error" class="error mt-3">
                        <span>{{ error }}</span>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <button type="submit" class="btn btn_submit mt-3" @click="submitReset()">
                    {{ $t("utils.submit") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            postData: {
                email: "",
            },
            message: false,
            error: false,
            inputIsActive: false
        }
    },
    methods: {
        submitReset() {
            this.error = false;
            const formData = new FormData();
            formData.append('email', this.postData.email);
            this.$store.getters.api.post('/auth/password-reset', formData).then(response => {
                if (response.data.error_code == 130) {
                    this.error = (this.$t("error.130"));
                }
                else {
                    this.message = (this.$t("general.emailSent"));
                }
            }).catch(error => {
                if (error.response) {
                    this.error = error.response.data.detail;
                }
            });
        },
        activateInput() {
            this.inputIsActive = true;
        },
        deactivateInput() {
            // Optional: Only deactivate if the input is empty
            this.inputIsActive = this.postData.email.length > 0;
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.inner {
    background: radial-gradient(circle, $light-purple 6%, $purple 100%);
    border-radius: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 45px 50px 90px 50px;
    margin: 0 0 20px 0;
}

.title {
    color: $white;
    font-size: 48px;
    text-shadow: 2px 2px 5px $black;
}

.input_group {
    background-color: $white;
    border-radius: 50px;

    label {
        color: $black;
        width: 20%;
        font-size: 20px;
    }

    input {
        background-color: $white;
        border: none;
        width: 100%;
        outline: none;
        border-radius: 50px;
        padding: 20px 20px;
        font-size: 20px;
    }
}

.input_group.active label {
    transition: all 0.2s ease-in-out;
    display: none
}

.input_group.active {
    background-color: $white;
    padding: 0
}

.input_group.active input {
    width: 100%;
    opacity: 1;
    padding: 20px 20px;
    border-radius: 50px;
    outline: none;
    border: none;
}

.error {
    color: $light-red;
    font-family: 'Rubik One Regular', sans-serif;
    font-size: 20px;
    background-color: $beige;
    border-radius: 35px;
    text-align: center;
    padding: 10px;
}

.message {
    color: $green;
    font-family: 'Rubik One Regular', sans-serif;
    font-size: 20px;
    background-color: $beige;
    border-radius: 35px;
    text-align: center;
    padding: 10px;
}
</style>