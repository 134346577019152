<template>
    <p>
        Tyto obchodní podmínky (dále jen „Podmínky") upravují pravidla a podmínky používání aplikace
        <b>Salesman</b> (dále jen „Aplikace"), kterou poskytuje Digital Wish s.r.o. (dále jen „Poskytovatel“), se
        sídlem Korunní 2569/108, Vinohrady, 101 00 Praha 10, IČO: 08269581, zapsaná v obchodním rejstříku
        vedeném Městským soudem v Praze, oddíl C, vložka 314939. Používáním Aplikace souhlasíte s těmito
        Podmínkami. Pokud s těmito Podmínkami nesouhlasíte, nemůžete Aplikaci používat.
    </p>

    <hr />

    <h2>1. Definice</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">
            1.1 <b>Aplikace</b>: Software poskytovaný Digital Wish s.r.o., který registrovaným
            uživatelům umožňuje vyhledávání informací o právnických, fyzických podnikajících osobách a
            dalších subjektech zařazených v databázi, identifikační, kontaktní údaje a další související
            informace. Veškeré zveřejněné údaje o subjektech pocházejí z veřejně dostupných rejstříků a
            databází (např. obchodního rejstříku, živnostenského rejstříku atd.)
        </li>
        <li class="list-group-item">
            1.2 <b>Uživatel</b>: Je konkrétní fyzická osob, která užívá Aplikaci.
        </li>
        <li class="list-group-item">
            1.3 <b>Služby</b>: Všechny služby a funkce poskytované v rámci Aplikace.
        </li>
        <li class="list-group-item">
            1.4 <b>Účet</b>: Uživatelům, kteří se zaregistrují do Aplikace, je vytvořen účet, který slouží k přístupu a užívání služeb.
        </li>
        <li class="list-group-item">
            1.5 <b>Zákazník</b>: Subjekt, který platí přístup do Aplikace svým Uživatelům.
        </li>
        <li class="list-group-item">
            1.6 <b>Správce firemního profilu</b>: Pověřená osoba Zákazníka ke správě firemního profilu, je zároveň také Uživatelem.
        </li>
    </ul>

    <h2>2. Registrace a používání účtu</h2>
    <ol class="list-group mb-4">
        <li class="list-group-item">2.1. Pro přístup k Aplikaci se Uživatel musí zaregistrovat a vytvořit si účet.</li>
        <li class="list-group-item">2.2. Uživatel je odpovědný za správnost a aktuálnost údajů uvedených při registraci.</li>
        <li class="list-group-item">2.3. Uživatel nesmí používat Aplikaci k nelegálním činnostem nebo k porušování právních předpisů.</li>
    </ol>

    <h2>3. Práva a povinnosti uživatele</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">3.1. Uživatel je povinen používat Aplikaci v souladu s těmito Podmínkami a platnými právními předpisy.</li>
        <li class="list-group-item">3.2. Zákazník určí svého správce firemního profilu. Správce firemního profilu má pravomoc přidávat a odebírat Uživatele Aplikace, měnit globální nastavení firmy v Aplikaci a má možnost nahlížet do vystavených faktur. Jednotlivé Uživatele může dále přidávat buď Poskytovatel nebo Správce Zákazníka.
        </li>
        <li class="list-group-item">3.3. Uživatel je povinen využívat data poskytovaná Aplikací výlučně v souladu s Nařízením GDPR, zákonem č. 110/2019 Sb., o zpracování osobních údajů, a dalšími právními předpisy.</li>
        <li class="list-group-item">3.4. V případě, že se Uživatel rozhodne údaje z Aplikace dále zpracovávat, stává se automaticky správcem těchto údajů a je povinen si sám zajistit právní ošetření zpracování tak, aby toto zpracování bylo provedeno v souladu s Nařízením GDPR. Digital Wish s.r.o. neodpovídá za zpracování údajů, které provede Uživatel a bude v rozporu s Nařízením GDPR a dalšími právními předpisy.</li>
        <li class="list-group-item">3.5. Pokud Uživatel předal či předá Poskytovateli osobní údaje jiných fyzických osob, případně je sám v Aplikaci uvede nebo doplní, je povinen tyto fyzické osoby sám informovat o zpracování osobních údajů a zajistit tak zákonnost zpracování osobních údajů. V opačném případě Uživatel odpovídá Poskytovateli za způsobenou škodu.</li>
        <li class="list-group-item">3.6. Informace o subjektech může Uživatel používat výhradně pro svoji vlastní potřebu.
        </li>
        <li class="list-group-item">3.7. Uživatel je oprávněn nahrát do Aplikace soubory pro svoji potřebu. Tyto nahrané soubory jsou na serveru uložené pomocí symetrického kódování, které umožňuje přístup všem oprávněným uživatelům, tedy těm, kteří mají k dispozici přístupový klíč, avšak soubor sám o sobě není čitelný. Velikost souboru je omezena tarifem služeb, který si Uživatel objednal.
        </li>
        <ul class="list-group-item">3.8. Uživatel nesmí:
            <li class="list-group-item">a) kopírovat, distribuovat nebo upravovat Aplikaci, ani její části,
            </li>
            <li class="list-group-item">b) využívat Aplikaci způsobem, který by mohl poškodit její funkčnost
                nebo bezpečnost,</li>
            <li class="list-group-item">c) používat Aplikaci k šíření virů, malware nebo jiného nelegálního
                obsahu.</li>
        </ul>
    </ul>

    <h2>4. Práva a povinnosti poskytovatele</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">4.1. Poskytovatel má právo změnit funkce nebo vlastnosti Aplikace bez předchozího upozornění.</li>
        <li class="list-group-item">4.2. Poskytovatel si vyhrazuje právo dočasně nebo trvale pozastavit nebo zrušit přístup k Aplikaci z důvodu porušení Podmínek nebo technických problémů.
        </li>
        <li class="list-group-item">4.3. Poskytovatel neručí za správnost a úplnost informací prezentovaných v Aplikaci a může kdykoliv bez předchozího upozornění provádět změny veškerých informací prezentovaných v Aplikaci. </li>
    </ul>

    <h2>5. Ochrana osobních údajů</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">5.1. Užíváním Aplikace může docházet ke zpracování osobních údajů uživatele. Takové zpracování se řídí nařízením Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen “Nařízení GDPR“), zákonem č. 110/2019 Sb., o zpracování osobních údajů a dalšími právními předpisy. Poskytovatel se zavazuje chránit osobní údaje uživatelů v souladu s platnými právními předpisy o ochraně osobních údajů.</li>
        <li class="list-group-item">5.2. Podrobnosti o ochraně osobních údajů jsou uvedeny v <a href="/gdpr"><b>Zásadách
            ochrany osobních údajů</b></a>.</li>
    </ul>

    <h2>6. Licenční podmínky</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">6.1. Poskytovatel uděluje Uživateli nevýhradní a nepřenosnou licenci k
            používání Aplikace pro osobní nebo obchodní účely v souladu s těmito Podmínkami.</li>
        <li class="list-group-item">6.2. Uživatel nesmí Aplikaci dekompilovat, zpětně analyzovat nebo
            vytvářet z ní odvozená díla.</li>
    </ul>


    <h2>7. Cena a platby</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">7.1. Aplikace je zpoplatněná.</li>
        <li class="list-group-item">7.2. Zákazník souhlasí s platbou příslušné částky v souladu s ceníkem Poskytovatele. Platby probíhají na základě zálohových faktur či faktur na účet Poskytovatele na měsíční či roční bázi.
            Účet je následně aktivován na zaplacenou dobu, a to buď
            <ul class="list-group-item">
                <li class="list-group-item">a) na zaplacenou dobu od datumu, od kterého je zaplaceno, pokud je v budoucnu
                </li>
                <li class="list-group-item">b) pokud je datum posledního možného užití v minulosti, tak od data zaplacení/připsání do aplikace</li>
            </ul>
        </li>
        <li class="list-group-item">7.3. Refundace, v případě odstoupení/snížení počtu platících Uživatelů zákazníka bez udání závažného důvodu, se neuplatňuje.
            V případě, že dojde k výpadku delšímu než 3 pracovní dny ze strany Poskytovatele, náleží Zákazníkovi refundace, či bezplatné prodloužení doby užívání Aplikace dle uvážení Poskytovatele.
            V případě ukončení služby ze strany Poskytovatele, budou Uživatelé v předstihu informováni a v případě ukončení služby před koncem splatnosti služby, bude vrácena poměrná část zaplacené částky.
        </li>
    </ul>

    <h2>8. Odpovědnost</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">8.1. Poskytovatel nenese odpovědnost za jakékoli škody či ušlý zisk
            vzniklé Uživateli v souvislosti s používáním Aplikace, pokud tyto škody nezpůsobila nedbalost
            nebo úmyslné jednání Poskytovatele.</li>
        <li class="list-group-item">8.2. Poskytovatel nenese odpovědnost za výpadky Aplikace způsobené
            vyšší mocí nebo událostmi, které jsou mimo jeho kontrolu.</li>
        <li class="list-group-item">8.3. Poskytovatel nenese žádnou odpovědnost za případnou škodu či ušlý
            zisk, které Uživatelům mohou vzniknout v souvislosti s užíváním Aplikace, i když vznikly na
            základě použití nebo nemožnosti použití Aplikace, a to i v případě, že by Poskytovatel byl
            označen jako možný původce těchto škod.</li>
        <li class="list-group-item">8.4. Poskytovatel neodpovídá za obsah sdělení, které si mezi sebou
            vyměňují Uživatelé některých částí Aplikace, a vyhrazuje si právo odstranit či nedoručit
            sdělení,
            které obsahuje jakoukoliv informaci, kterou je možné považovat za protiprávní, urážlivou nebo
            jinak nepřijatelnou. Vyhodnocení obsahu zprávy je výhradně v kompetenci Poskytovatele.</li>
        <li class="list-group-item">8.5. Poskytovatel nenese žádnou odpovědnost za obsah internetových
            stránek třetích subjektů, které lze navštívit prostřednictvím Aplikace.</li>
        <li class="list-group-item">8.6. Poskytovatel nenese odpovědnost za nepřesnost nebo neúplnost údajů,
            dále za údaje, které by z nějakého důvodu veřejně dostupné být neměly, a to z pohledu, že tyto
            údaje
            pocházejí z veřejně dostupných rejstříků.</li>
        <li class="list-group-item">8.7. Poskytovatel nenese žádnou odpovědnost za reklamu nebo jinou formu
            propagace prováděnou jakýmkoliv třetím subjektem prostřednictvím Aplikace.</li>
    </ul>

    <h2>9. Ukončení užívání Aplikace</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">9.1. Účet Uživatele je možné aktivovat Poskytovatelem nebo jiným Uživatelem aplikace. Nový Uživatel se následně registruje. Zneaktivnit lze pouze zásahem Poskytovatele.
        </li>
        <li class="list-group-item">9.2. Poskytovatel si vyhrazuje právo zrušit nebo pozastavit přístup k
            Účtu uživatele, pokud dojde k porušení Podmínek.</li>
    </ul>

    <h2>10. Změny podmínek</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">10.1. Poskytovatel si vyhrazuje právo kdykoli změnit tyto Podmínky. O
            změnách budou Uživatelé informováni prostřednictvím Aplikace nebo e-mailem.</li>
        <li class="list-group-item">10.2. Používání Aplikace po změně Podmínek bude považováno za souhlas s
            těmito změnami.</li>
        <li class="list-group-item">10.3. V případě, že některá část těchto uživatelských podmínek bude
            považována za neplatnou nebo v rozporu s platným právním řádem, ostatní části těchto
            uživatelských podmínek zůstávají i nadále v platnosti.</li>
    </ul>

    <h2>11. Rozhodné právo a řešení sporů</h2>
    <ul class="list-group mb-4">
        <li class="list-group-item">11.1. Tyto Podmínky se řídí právním řádem České republiky.</li>
        <li class="list-group-item">11.2. Všechny spory vzniklé v souvislosti s používáním Aplikace budou
            řešeny příslušným soudem v Praze.</li>
    </ul>

    <h2>12. Kontakty</h2>
    <p>
        Pokud máte jakékoliv dotazy nebo připomínky k těmto Podmínkám, kontaktujte nás na:
    <div class="row">
        <span>Digital Wish s.r.o</span>
        <span><a href="mailto:marek@digitalwish.cz">marek@digitalwish.cz</a></span>
        <span><a href="tel:+420736109281">736 109 281</a></span>
    </div>
    </p>

    <p style="font-weight: bold">
        Tyto uživatelské podmínky nabývají platnosti a účinnosti dne 1. prosince 2024.
    </p>
</template>

<script>
export default {};
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
a {
    color: $purple;
}
</style>
