<template>
    <CompanyBoxContent v-if="company && company.vr && company.vr.insolvence" :title="$t('company.insolvency') + ':'">
        <template #data>
            <div class="col-12 box">
                <div v-for="insolvency in company.vr.insolvence" :key="insolvency.id" class="row">
                    <div v-for="insolvencyRecord in insolvency.insolvencniZapis" :key="insolvencyRecord.id"
                        class="mt-2">
                        <span>{{ insolvencyRecord.text }}</span>
                        <div class="mt-3">
                            <span>{{ $t('company.dateOfRegistration') }}: </span>
                            <i18n-d v-if="insolvencyRecord.datumZapisu" tag="span" :value="new Date(insolvencyRecord.datumZapisu)"></i18n-d>
                        </div>
                        <div class="mt-1">
                            <span>{{ $t('company.dateOfRemoval') }}: </span>
                            <i18n-d v-if="insolvencyRecord.datumVymazu" tag="span" :value="new Date(insolvencyRecord.datumVymazu)"></i18n-d>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {

        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";
</style>