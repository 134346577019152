<template>
    <CompanyBoxContent v-if="company.vr && company.vr.spolecnici" :title="$t('company.actualOwners') + ':'">
        <template #data>
            <div v-for="(owner, index) in filteredCurrentOwners(company.vr.spolecnici)" :key="index" class="mt-2 box">
                <div class="mb-2 row">
                    <span v-if="owner.jmeno && owner.prijmeni">{{ owner.jmeno }} {{ owner.prijmeni }}</span>
                    <div v-if="!owner.obchodniJmeno" class="row">
                        <span v-if="owner.age">{{ $t('company.age') }}: {{ owner.age }}</span>
                        <span v-if="owner.město">{{ owner.město }}</span>
                    </div>
                    <div v-if="owner.obchodniJmeno" class="row">
                        <span>{{ owner.obchodniJmeno }}</span>
                        <span v-if="owner.textovaAdresa">{{ owner.textovaAdresa }}</span>
                    </div>
                    <span>
                        {{ $t('company.owner') }} {{ $t('general.from') }}
                        <i18n-d v-if="owner.datumZapisu" tag="span" :value="new Date(owner.datumZapisu)"></i18n-d>
                    </span>
                </div>
            </div>
            <div v-if="oldOwners && oldOwners.length > 0" class="mt-4">
                <div class="title">
                    <h3>{{ $t('company.oldOwners') }}:</h3>
                </div>
                <div v-for="(owner, index) in filteredOldOwners(company.vr.spolecnici)" :key="index" class="mt-2 box">
                    <div v-if="owner.datumVymazu !== null" class="mb-2 row">
                        <span v-if="owner.jmeno && owner.prijmeni">{{ owner.jmeno }} {{ owner.prijmeni }}</span>
                        <div v-if="!owner.obchodniJmeno" class="row">
                            <span v-if="owner.age">{{ $t('company.age') }}: {{ owner.age }}</span>
                            <span v-if="owner.město">{{ owner.město }}</span>
                        </div>
                        <div v-if="owner.obchodniJmeno" class="row">
                            <span>{{ owner.obchodniJmeno }}</span>
                            <span v-if="owner.textovaAdresa">{{ owner.textovaAdresa }}</span>
                        </div>
                        <span>
                            {{ $t('company.owner') }} {{ $t('general.from') }}
                            <i18n-d v-if="owner.datumZapisu" tag="span" :value="new Date(owner.datumZapisu)"></i18n-d>
                            {{ $t('general.to') }}
                            <i18n-d v-if="owner.datumVymazu" tag="span" :value="new Date(owner.datumVymazu)"></i18n-d>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue";

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent
    },
    computed: {
        oldOwners() {
            const oldOwnersList = this.filteredOldOwners(this.company.vr.spolecnici);

            return oldOwnersList;
        }
    },
    methods: {
        filteredCurrentOwners(spolecnici) {
            return spolecnici.filter(spolecnik => !spolecnik.datumVymazu);
        },
        filteredOldOwners(spolecnici) {
            // Filter old owners who have a removal date and whose removal date is different from their registration date
            return spolecnici.filter(spolecnik =>
                spolecnik.datumVymazu && spolecnik.datumVymazu !== spolecnik.datumZapisu
            );
        },

    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    padding: 0 0 5px 20px;

    h3 {
        font-weight: 700;
        font-size: 24px;
    }
}
</style>
