<template>
    <div class="d-flex justify-content-center align-items-center w-100 vh-100 p-5">
        <h2 class="text-center">{{ $t("general.noInstitution") }}</h2>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";
</style>