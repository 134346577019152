<template>
    <p>
        Smyslem tohoto sdělení je poskytnout informace zejména o tom, jaké osobní údaje shromažďujeme, jak s nimi nakládáme, z jakých zdrojů je získáváme, k jakým účelům je využíváme, komu je smíme poskytnout, kde můžete získat informace o Vašich osobních údajích a jaká jsou Vaše individuální práva v oblasti ochrany osobních údajů. Při zpracování osobních údajů se řídíme obecně závaznými právními předpisy a zpracování osobních údajů probíhá vždy pouze v rozsahu daném konkrétní službou nebo účelem zpracování.
    </p>

    <hr />

    <h2>Obecné informace</h2>
    <p>S ohledem na naši podnikatelskou činnost jsme povinni zpracovávat některé osobní údaje, a to zejména pro účely plnění zákonných a smluvních povinností. V tomto ohledu bychom bez poskytnutí osobních údajů nemohli naše služby poskytnout.</p>

    <h2>Zásady zpracování osobních údajů</h2>
    <p>Při zpracování osobních údajů ctíme a respektujeme nejvyšší možné standardy ochrany osobních údajů a dodržujeme zejména následující zásady:</p>
    <ul class="list-group mb-4">
        <li class="list-group-item">Osobní údaje vždy zpracováváme pro jasně a srozumitelně stanovený účel, stanovenými prostředky, stanoveným způsobem, a pouze po dobu, která je nezbytná vzhledem k účelům jejich zpracování; zpracováváme pouze přesné osobní údaje klientů a máme zajištěno, že jejich zpracování odpovídá stanoveným účelům a je nezbytné pro naplnění těchto účelů;
        </li>
        <li class="list-group-item">Osobní údaje chráníme jako utajované informace; proto zpracováváme osobní údaje způsobem, který zajišťuje nejvyšší možnou bezpečnost těchto údajů a který zabraňuje jakémukoliv neoprávněnému nebo nahodilému přístupu k osobním údajům, k jejich změně, zničení či ztrátě, neoprávněným přenosům, k jejich jinému neoprávněnému zpracování, jakož i k jinému zneužití
        </li>
        <li class="list-group-item">Vždy srozumitelně informujeme o zpracování osobních údajů a o nárocích na přesné a úplné informace o okolnostech tohoto zpracování, jakož i o dalších souvisejících právech;</li>
        <li class="list-group-item">Nastavili jsme a dodržujeme odpovídající technická a organizační opatření, aby byla zajištěna úroveň zabezpečení odpovídající všem možným rizikům; veškeré osoby, které přicházejí do styku s osobními údaji, mají povinnost dodržovat mlčenlivost o informacích získaných v souvislosti se zpracováním těchto údajů.</li>
    </ul>

    <h2>Účely zpracovávání a právní základ pro zpracování</h2>
    <h3>1. 	Zpracování osobních údajů bez souhlasu</h3>
    <p>Jde většinou o situace, kdy jste nám povinni předat určité osobní údaje jako podmínku toho, abychom Vám mohli poskytnout svoje služby, případně kdy jsme oprávněni zpracovávat Vaše osobní údaje získané jiným způsobem.</p>
    <p>Osobní údaje jsme oprávněni ze zákona zpracovávat bez Vašeho souhlasu pro následující účely dodržení našich právních povinností, zejména:</p>
    <h4>1) Předcházení vzniku škod na majetku naší společnosti</h4>
    <ul class="list-group mb-4">
        <li class="list-group-item">prevence podvodného jednání, kterému může být naše společnost vystavena;
        </li>
        <li class="list-group-item">splnění případné povinnosti při identifikaci a kontrole klienta dle zákona o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu.
        </li>
    </ul>
    <p style="font-weight: bold">
        Tyto uživatelské podmínky nabývají platnosti a účinnosti dne 1. prosince 2024.
    </p>
</template>

<script>
export default {};
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
a {
    color: $purple;
}
h2 {
    text-align: center;
}
</style>
