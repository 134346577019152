<template>
    <div id="map2" class="w-100">
        <button @click="sizeUp = !sizeUp" type="button"
            class="resize-map-btn btn btn_main d-flex align-items-center justify-content-center" data-bs-toggle="modal"
            data-bs-target="#enlargeMapModal2">
            <icon icon="Resize" class="me-2 resize_icon"></icon>
            <span>{{ $t('company.resizeMap') }}</span>
        </button>

        <div class="modal fade" id="enlargeMapModal2" tabindex="-1" aria-labelledby="enlargeMapModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered custom_modal">
                <div class="modal-content modal_wrapper">
                    <div class="modal-header border-0">
                    </div>
                    <div class="modal-body">
                        <div id="modal-map2" style="width: 100%; height: 700px;"></div>
                    </div>
                    <div class="modal-footer border-0">
                        <button type="button" class="btn btn_secondary" data-bs-dismiss="modal">
                            {{ $t('utils.close') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        companyAddress: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            sizeUp: false,
            map: null,
            modalMap: null,
        }
    },
    mounted() {
        this.createData();
    },
    watch: {
        companyAddress: {
            handler: function () {
                this.$nextTick(() => {
                    this.createData();
                })
            },
            deep: true
        },
        sizeUp(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.createModalMap();
                });
            }
        }
    },
    methods: {
        createData: function () {
            const branchAddresses = this.companyAddress.filter(item => item.branch === true);

            if (!branchAddresses.length) {
                console.error('No valid branch addresses.');
                return;
            }

            // Remove the existing map if it exists
            if (this.map) {
                this.map.remove();
                this.map = null;
            }

            this.map = L.map('map2', {
                attributionControl: false,
                zoomControl: false
            });

            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                maxZoom: 19
            }).addTo(this.map);

            const bounds = [];

            branchAddresses.forEach(item => {
                if (item.address.lat && item.address.lon) {
                    const marker = L.marker([item.address.lat, item.address.lon]).addTo(this.map)
                        .bindPopup
                        (`<div>
                            <strong>Adresa:</strong> ${item.address.whole_address} <br>
                            <strong>Datum založení:</strong> ${item.date_from ? item.date_from : 'N/A'} <br>
                        </div>`);

                    bounds.push([item.address.lat, item.address.lon]);
                } else {
                    console.warn('Invalid branch coordinates:', item);
                }
            });

            if (bounds.length > 0) {
                this.map.fitBounds(bounds);
                this.map.setZoom(6);
            }
        },

        createModalMap: function () {
            const branchAddresses = this.companyAddress.filter(item => item.branch === true);

            if (!branchAddresses.length) {
                console.error('No valid branch addresses.');
                return;
            }

            // Remove the existing modal map if it exists
            if (this.modalMap) {
                this.modalMap.remove();
                this.modalMap = null;
            }

            this.modalMap = L.map('modal-map2', {
                attributionControl: false
            });

            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                maxZoom: 19
            }).addTo(this.modalMap);

            const modalBounds = [];

            branchAddresses.forEach(item => {
                if (item.address.lat && item.address.lon) {
                    const marker = L.marker([item.address.lat, item.address.lon]).addTo(this.modalMap)
                        .bindPopup(`<div>
                            <strong>Adresa:</strong> ${item.address.whole_address} <br>
                            <strong>Datum založení:</strong> ${item.date_from ? item.date_from : 'N/A'} <br>
                        </div>`);

                    modalBounds.push([item.address.lat, item.address.lon]);
                } else {
                    console.warn('Invalid branch coordinates:', item);
                }
            });

            if (modalBounds.length > 0) {
                this.modalMap.fitBounds(modalBounds);
            }

            const modalElement = document.getElementById('enlargeMapModal2');
            modalElement.addEventListener('shown.bs.modal', () => {
                this.$nextTick(() => {
                    if (this.modalMap) {
                        this.modalMap.invalidateSize();
                        this.modalMap.fitBounds(modalBounds);  // Needs to be here because, map is loaded after opening modal
                    }
                });
            });
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.resize-map-btn {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 60%;

    span {
        font-size: 18px;
    }
}

.resize_icon {
    width: 31px;
    height: 31px;
}

.custom_modal {
    max-width: 75%;
}

@media screen and (max-width: 1400px) {
    .resize-map-btn {
        width: 100%;
        font-size: 16px;

        span {
            font-size: 16px;
        }
    }

    .resize_icon {
        width: 25px;
        height: 25px;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .resize-map-btn {
        width: 85%;
        font-size: 16px;

        span {
            font-size: 16px;
        }
    }

    .resize_icon {
        width: 25px;
        height: 25px;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
    .resize-map-btn {
        width: 70%;
        font-size: 17px;

        span {
            font-size: 16px;
        }
    }

    .resize_icon {
        width: 30px;
        height: 30px;
    }
}
</style>
