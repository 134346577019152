<template>
    <CompanyBoxContent v-if="dph" :title="`${$t('company.currentAccounts')} (${$t('company.byDphRegister')}):`">
        <template #data>
            <div class="box p-0">
                <div v-if="dph.bankovniUcty && dph.bankovniUcty.length"
                    v-for="(currentAccounts, index) in dph.bankovniUcty" :key="index"
                    :class="['p-3 inner', index % 2 === 0 ? 'bg' : '']">
                    <span v-if="currentAccounts.cisloUctu" class="col-12 col-xxl-6" style="word-break: break-word;">
                        {{ currentAccounts.cisloUctu }}
                    </span>
                    <span v-if="currentAccounts.banka" class="col-12 col-xxl-6">({{ currentAccounts.banka }})</span>
                    <div v-if="currentAccounts.datumUctu" class="col-12">
                        <span class="pe-1">od: </span>
                        <i18n-d v-if="currentAccounts.datumUctu" tag="span"
                            :value="new Date(currentAccounts.datumUctu)"></i18n-d>
                    </div>
                </div>
            </div>
            <div v-if="oldAccounts && oldAccounts.length > 0" class="mt-4">
                <div class="title">
                    <h3>{{ $t('company.historyAccounts') }}:</h3>
                </div>
                <div class="box p-0">
                    <div v-if="dph.bankovniUcty && dph.bankovniUcty.length"
                        v-for="(oldAccounts, index) in dph.historieBankovnichUctu" :key="index"
                        :class="['p-3 inner', index % 2 === 0 ? 'bg' : '']">
                        <span v-if="oldAccounts.cisloUctu" class="col-12 col-xxl-6">{{ oldAccounts.cisloUctu }}</span>
                        <span v-if="oldAccounts.banka" class="col-12 col-xxl-6">({{ oldAccounts.banka }})</span>
                        <div class="row">
                            <div v-if="oldAccounts.datumZverejneni" class="col-12 col-xxl-6">
                                <span class="pe-2">{{ $t("general.from") }}:</span>
                                <i18n-d tag="span" :value="new Date(oldAccounts.datumZverejneni)"></i18n-d>
                            </div>
                            <div v-if="oldAccounts.datumUkonceniZverejneni" class="col-12 col-xxl-6">
                                <span class="pe-2">{{ $t("general.to") }}:</span>
                                <i18n-d tag="span" :value="new Date(oldAccounts.datumUkonceniZverejneni)"></i18n-d>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>


</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {

        }
    },
    computed: {
        dph() {
            return this.company.dph ? this.company.dph : null;
        },
        oldAccounts() {
            if (this.company && this.company.dph) {
                return this.company.dph.historieBankovnichUctu || [];
            }
            return [];
        }
    },
    methods: {
        isValidDate(dateString) {
            // Check if the date string can be converted to a valid Date object and not shows Invalid date
            const date = new Date(dateString);
            return !isNaN(date.getTime());
        },
        formatDate(dateString) {
            // Format the date if valid
            const date = new Date(dateString);
            return date.toLocaleDateString();
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    padding: 0 0 5px 20px;

    h3 {
        font-weight: 700;
        font-size: 24px;
    }
}

.inner {
    border-radius: 35px;
}

.bg {
    background-color: $grey;
}
</style>