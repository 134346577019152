<template>
  <div class="block">
    <div class="profile">
      <div class="row p-3">
        <!-- Avatar image with upload photo button -->
        <div class="col-sm-12 col-md-12 col-xl-6 col-xxl-6">
          <div class="left">
            <div class="avatar text-center">
              <img :src="userAvatarUrl" class="img-fluid rounded" :alt="$t('user.avatar')">
            </div>
            <div class="text-center mt-4">
              <button class="btn btn_main upload_photo" data-bs-toggle="modal" data-bs-target="#uploadPhoto">
                <icon icon="Upload" class="me-1"></icon>
                <span>{{ $t("user.uploadAvatar") }}</span>
              </button>
            </div>
          </div>
        </div>

        <!-- Right side -->
        <div class="col-sm-12 col-md-12 col-xl-6 col-xxl-6">
          <!-- Header -->
          <div class="right_side mx-auto row" :class="{ 'editable_right_side': editable }">
            <div class="d-flex flex-wrap justify-content-between">
              <div class="title text-center mx-auto">
                <h1 class="py-1">{{ editable ? $t("user.changePersonalDetails") : $t("user.personalDetails") }}</h1>
              </div>
            </div>
            <!-- Information fields -->
            <div class="mt-4 row" :class="{ 'px-0': editable }">
              <div class="data" :class="{ 'col-12 w-100 d-flex flex-wrap': editable }">
                <EditableRow :header="$t('user.firstName')" :editable="editable" :body="userEdited.first_name"
                  @body="(newValue) => { userEdited.first_name = newValue }" :class="{ 'col-6': editable }" />
                <EditableRow :header="$t('user.lastName')" :editable="editable" :body="userEdited.last_name"
                  @body="(newValue) => { userEdited.last_name = newValue }" :class="{ 'col-6': editable }" />
              </div>
              <div class="data" :class="editable ? 'col-12 d-flex flex-wrap w-100 mt-0' : 'mt-5 w-100'">
                <EditableRow :header="$t('user.email')" :editable="editable" :inputType="'email'"
                  :body="userEdited.email" @body="(newValue) => { userEdited.email = newValue }" class="email"
                  :class="{ 'mt-2': editable }" />
                <EditableRow :header="$t('user.phone') + ' ' + '(' + $t('general.optional') + ')'" :editable="editable"
                  :body="userEdited.phone" @body="(newValue) => { userEdited.phone = newValue }" class="phone"
                  :class="{ 'mt-2': editable }" />
              </div>
              <div class="data col-6 text-center mx-auto" :class="editable ? 'mt-0 mb-3 w-100' : 'mt-5'">
                <EditableRow v-if="editable" class="active_password" :header="$t('user.activePassword')"
                  :editable="editable" :inputType="'password'" :body="oldPassword" :class="{ 'mt-2': editable }"
                  @body="(newValue) => { oldPassword = newValue }" />
              </div>
            </div>
          </div>
          <!-- Edit button -->
          <div class="text-center mt-4" :class="{ 'editable_buttons' : editable }">
            <button v-if="editable" class="btn btn_main text-nowrap me-2" style="width: 230px; height: 42px;" @click="editData(false)">
              {{ $t("utils.closeWithoutSaving") }}
            </button>
            <button class="btn btn_main text-nowrap" style="width: 230px; height: 42px;" @click="editData(true)">
              <icon icon="Edit" class="me-1" />
              &nbsp;{{ editable ? $t('utils.saveChanges') : $t('utils.editDetails') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <Popup :header="$t('user.uploadAvatar')" :editable="true" id="uploadPhoto" @save-data="uploadPhotoToServer">
      <template v-slot:editor>
        <input type="file" class="form-control" accept="image/*" @change="changePhoto">
      </template>
    </Popup>
  </div>
</template>

<script>
import EditableRow from "@/components/EditableRow.vue"
import Popup from "@/components/Popup.vue";

export default {
  components: {
    EditableRow,
    Popup
  },
  data() {
    return {
      user: {},
      userEdited: {},
      editable: false,
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      photo: null,
      hrefPhoto: null
    }
  },
  mounted: function () {
    this.user = this.$store.getters.user;
    this.userEdited = JSON.parse(JSON.stringify(this.user));
    if (this.user && this.user.avatar) {
      this.downloadPhoto();
    }
  },
  computed: {
    userAvatarUrl: function () {
      if (this.user && this.user.avatar && this.hrefPhoto) {
        return this.hrefPhoto;
      }
      return "https://via.placeholder.com/1000";
    },
  },
  methods: {
    editData(save) {
      if (this.editable && save === true) {
        if (!this.oldPassword) {
          alert(this.$t("user.enterCurrentPassword"));
          return;
        } else if (this.oldPassword && this.newPassword && this.newPassword !== this.newPasswordRepeat) {
          alert(this.$t("user.newPasswordsNotMatch"));
          return;
        }
        this.userEdited.name = this.userEdited.first_name + " " + this.userEdited.last_name;
        const editedData = {
          ...this.userEdited,
          old_password: this.oldPassword,
          new_password: this.newPassword
        }
        this.$store.getters.api.put("/auth/user", editedData)
          .then((response) => {
            this.$store.commit("saveUserProfile", response.data.user);
            this.editable = false;
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              alert(this.$t("error." + error.response.data.error_code));
            }
            else {
              alert(this.$t("error.undefined" + ": " + error));
            }
          });
      } else if (this.editable) {
        this.editable = false;
        this.userEdited = JSON.parse(JSON.stringify(this.user));
      } else {
        this.editable = true;
      }
    },
    changePhoto(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Create a square canvas and draw the image in the center
          const canvas = document.createElement("canvas");
          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size
          );

          // Convert the canvas to a file-like object
          canvas.toBlob((blob) => {
            this.photo = blob;
          }, "image/jpeg");
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    uploadPhotoToServer: function () {
      let formData = new FormData();
      formData.append('avatar', this.photo);
      console.log("Avatar in FormData:", formData.get('avatar'));
      this.$store.getters.api.post('/auth/avatar',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function () {
        this.user.avatar = true;
        this.downloadPhoto();
      }.bind(this));
    },
    downloadPhoto: function () {
      this.$store.getters.api.get('/auth/avatar',
        {
          responseType: 'blob'
        }).then((response) => {
          this.hrefPhoto = URL.createObjectURL(response.data);
        });
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.profile {
  background-color: $white;
  color: $dark-grey;
  border-radius: 35px;
  margin: 16px 8px;
  padding: 20px 15px 25px 15px;
}

svg {
  width: 30px;
  height: 30px;
  stroke: $white;
  background-color: transparent;
}

.left {
  width: 400px;
  height: 400px;
}

.avatar {
  position: relative;
  border: 20px solid $light-purple;
  border-radius: 35px;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  h1 {
    font-size: 45px;
    font-weight: 500;
    font-family: 'Rubik One Regular', sans-serif;
  }
}

.right_side {
  border: 5px solid $light-purple;
  border-radius: 35px;
  height: 400px;
  padding: 10px 20px;
}

.data {
  font-size: 28px;
}

.active_password {
  font-weight: 600;
}

@media (max-width: 750px) {
  .left {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .avatar {
    width: 300px;
    height: 300px;
  }

  .right_side {
    height: 500px;
    margin-top: 90px;
  }

  .editable_right_side {
    height: 680px;
  }
}

@media (min-width: 750px) and (max-width: 1200px) {
  .left {
    width: 500px;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .avatar {
    width: 500px;
    height: 500px;
  }

  .right_side {
    height: 500px;
    margin-top: 90px;
  }

  .editable_right_side {
    height: 680px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .editable_right_side {
    height: 580px;
  }
  .editable_buttons {
    button {
      margin-top: 5px;
    }
  }
}

@media (min-width: 1500px) and (max-width: 1650px) {
  .left {
    width: 450px;
    height: 450px;
  }

  .right_side {
    border: 5px solid $light-purple;
    border-radius: 35px;
    height: 450px;
    padding: 10px 20px;
  }

  .editable_right_side {
    height: 580px;
  }
}

@media (min-width: 1650px) and (max-width: 1900px) {
  .left {
    width: 500px;
    height: 500px;
  }

  .right_side {
    border: 5px solid $light-purple;
    border-radius: 35px;
    height: 500px;
    padding: 10px 20px;
  }
}

@media (min-width: 1900px) and (max-width: 2200px) {
  .left {
    width: 550px;
    height: 550px;
  }

  .right_side {
    border: 5px solid $light-purple;
    border-radius: 35px;
    height: 550px;
    padding: 10px 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1750px) {
  .title {
    h1 {
      font-size: 35px;
    }
  }

  .data {
    font-size: 25px;
  }

  .btn_main {
    font-size: 18px;

    .span {
      font-size: 18px;
    }
  }

  .email {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, ));
  }

  .phone {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, ));
  }
}
</style>