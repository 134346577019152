<template>
    <CompanyBoxContent v-if="company.trades && company.trades.length > 0"
        :title="$t('company.trades') + ' ' + '(' + $t('general.total') + ': ' + company.trades.length + ')' + ':'">
        <template #data>
            <div class="box p-0">
                <div v-for="(trade, index) in visibleTrades" :key="index"
                    :class="['p-3 inner', index % 2 === 0 ? 'bg' : '']">
                    <span>{{ trade.name }}</span>
                    <span v-if="trade.trade_type">
                        ({{ trade.trade_type.name }})
                    </span>
                </div>
            </div>
        </template>

        <template #toggle-button>
            <!-- Button to toggle visibility -->
            <div class="text-center toggle mt-3">
                <button class="btn btn_main" type="button" data-bs-toggle="modal"
                    data-bs-target="#tradesModal">
                    <icon icon="ChevronDownWhite"></icon>
                    {{ $t('general.showMore') }}
                </button>
            </div>
        </template>
    </CompanyBoxContent>

    <div class="modal fade" id="tradesModal" tabindex="-1" aria-labelledby="tradesModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal_wrapper">
                <div class="modal-header border-0">
                    <h5 class="modal-title title" id="tradesModalLabel">{{ $t('company.trades') }}</h5>
                </div>
                <div class="modal-body p-0">
                    <ul class="list-group">
                        <li v-for="(trade, index) in company.trades" :key="index.id" class="list-group-item"
                            :class="['p-3', (index + visibleCount) % 2 === 0 ? 'bg' : '']">
                            {{ trade.name }}
                            <span v-if="trade.trade_type">
                                ({{ trade.trade_type.name }})
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn_secondary" data-bs-dismiss="modal">
                        {{ $t('utils.close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {
            visibleCount: 6,
        }
    },
    computed: {
        visibleTrades() {
            return this.company.trades.slice(0, this.visibleCount);
        },
        hiddenTrades() {
            return this.company.trades.slice(this.visibleCount);
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.inner {
    border-radius: 35px;

    p {
        padding: 5px 20px;
    }
}

.bg {
    background-color: $grey;
}

.toggle {
    svg {
        stroke: $white;

    }
}
</style>