<template>
    <select v-model="institution" class="form-select w-100">
        <option v-for="(institution, i) in $store.getters.institutions" :key="`Institution${i}`"
            :value="institution.id">
            {{ institution.name }}
        </option>
    </select>
</template>

<script>
export default {
    data() {
        return {
            institution: null
        }
    },
    mounted: function () {
        this.setFromStore();
    },
    watch: {
        institution: function (v) {
            if (v !== null && v !== undefined) {
                this.$store.commit("setInstitution", v);
            }
        }
    },
    methods: {
        setFromStore: function () {
            if (this.$store.getters.selectedInstitution) {
                this.institution = this.$store.getters.selectedInstitution;
            }
            else {
                setTimeout(this.setFromStore, 100);
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

select {
    background-color: $dark-purple;
    color: $white;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    padding: 10px 15px;
    font-size: 18px;
    border: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}
</style>