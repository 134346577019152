<template>
    <div class="modal fade" id="updatesModal" tabindex="-1" aria-labelledby="updatesModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content modal_wrapper">
                <div class="modal-header border-0">
                    <h2 class="title" id="updatesModalLabel">{{ $t('general.updates') }}</h2>
                </div>
                <div class="modal-body box">
                    <div v-if="updates && updates.length > 0" class="mb-3">
                        <div v-for="update in updates" :key="update.id">
                            <div>
                                <h3>{{ $t('general.version') }} {{ update.version_number }}:</h3>
                            </div>
                            <div v-if="update.new_features && update.new_features.length > 0" class="new mt-3">
                                <h4 class="">{{ $t('general.newFeatures') }}:</h4>
                                <div v-for="new_feature in update.new_features" :key="new_feature.id">
                                    <span>• {{ new_feature.name }}</span>
                                </div>
                            </div>
                            <div v-if="update.changed_features && update.changed_features.length > 0"
                                class="changes mt-3">
                                <h4 class="">{{ $t('general.changedFeatures') }}:</h4>
                                <div v-for="change in update.changed_features" :key="change.id">
                                    <span>• {{ change.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn_main px-5" @click="submitSeen">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from "bootstrap"; // Import Bootstrap's modal class

export default {
    data() {
        return {
            updates: [],
            user: {},
            modalInstance: null,
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.$store.getters.api.get('/updates').then(res => {
                this.updates = res.data.items;
                this.user = this.$store.state.user;

                if (this.updates.length > 0 && !this.user.seen_updates) {
                    this.showModal();
                }
            })
        },
        showModal() {
            const modalElement = document.getElementById("updatesModal");

            // Remove existing backdrops to prevent duplication
            document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());

            if (!this.modalInstance && modalElement) {
                this.modalInstance = new Modal(modalElement, { backdrop: "static", keyboard: false });
            }

            // Check if the modal is not already shown
            if (modalElement && !modalElement.classList.contains("show")) {
                this.modalInstance.show();
            }
        },
        submitSeen() {
            this.$store.getters.api.post(`/update/user/${this.user.id}/seen`).then(res => {
                this.$emit('seen', true);
                if (this.modalInstance) {
                    this.modalInstance.hide();
                    this.modalInstance = null;
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.modal_wrapper {
    padding: 15px 30px 15px 30px;
    border-radius: 50px;

    .modal-body {
        border-radius: 50px;
        padding: 15px 30px 30px 30px;
    }
}
</style>