<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 1122 1122" style="enable-background:new 0 0 1122 1122;" xml:space="preserve">
    <g id="Bg">
      <rect style="fill:#FFFFFF;" width="1122" height="1122" />
    </g>
    <g id="Object">
      <g>
        <path style="fill:none;"
          d="M740.029,502.858v-12.603c0-7.396-5.289-13.469-12.388-15.327c-0.612,2.282-1.337,4.515-2.046,6.751
c0.768,5.979,1.337,12.022,1.337,18.208c0,6.385-0.502,12.649-1.271,18.849C733.759,517.601,740.029,510.961,740.029,502.858z" />
        <path style="fill:none;"
          d="M691.673,459.177c-57.434-4.233-215.102-41.181-215.102-41.181
c-9.55,16.964-34.503,38.521-53.972,52.354c-0.714,3.035-1.386,6.058-1.876,9.16c-1.053,6.67-1.761,13.436-1.761,20.377
c0,4.957,0.315,9.835,0.865,14.664c0.896,8.243,2.639,16.237,5.023,23.978c17.369,56.539,71.795,97.94,136.153,97.94
s118.76-41.417,136.141-97.952c2.379-7.744,4.127-15.723,5.035-23.984c0.539-4.812,0.853-9.689,0.853-14.646
c0-6.942-0.708-13.708-1.761-20.377c-1.09-6.911-2.784-13.581-4.897-20.129C694.88,459.368,693.319,459.301,691.673,459.177z" />
        <path style="fill:#FFFFFF;" d="M581.017,704.555l19.706,129.423h23.603l36.663-192.507c-3.607-0.27-7.19-0.536-10.858-0.536
h-11.819c-23.1,11.787-49.379,18.52-77.309,18.52s-54.22-6.733-77.303-18.52h-11.82c-3.685,0-7.268,0.266-10.857,0.536
l36.663,192.507h23.585l19.706-129.423h0.69l-19.342-35.532h77.345l-19.342,35.532H581.017z" />
        <path style="fill:#616E7D;"
          d="M660.989,641.471l-36.663,192.507h-23.603h-79.451h-23.585l-36.663-192.507
c-74.858,5.571-133.902,67.913-133.902,144.214v70.243h467.758v-70.243C794.879,709.384,735.841,647.042,660.989,641.471z" />
        <polygon style="fill:#AAD4C8;" points="521.271,833.978 600.723,833.978 581.017,704.555 580.328,704.555 599.67,669.023
522.325,669.023 541.667,704.555 540.977,704.555 " />
        <path style="fill:#616E7D;" d="M358.065,490.254v12.603c0,21.575,18.235,39.102,40.646,39.102h2.39
c12.225,42.864,42.582,78.55,82.599,98.975c23.082,11.787,49.373,18.52,77.303,18.52s54.208-6.733,77.309-18.52
c40.016-20.426,70.355-56.112,82.58-98.975h2.39c22.411,0,40.646-17.527,40.646-39.102v-12.603
c0-18.68-13.72-34.258-31.973-38.113c0.641-5.617,1.083-11.293,1.083-17.103l-0.079-1.634c0.03-4.139-0.109-8.418-0.66-13.015
v-0.015c-2.499-26.657-11.783-51.346-26.114-72.304c-7.384-10.809-16.195-20.553-26.018-29.129
c-26.26-22.955-60.297-37.147-97.698-38.046l-1.888-1.495c-1.61-1.764-3.371-2.079-4.563-2.079c-2.802,0-4.77,1.858-6.361,3.383
h-0.514c-9.03-9.048-15.82-14.634-21.642-14.634h-0.03c-5.55,0.426-31.58,9.665-39.889,15.91
c-1.852,1.386-3.916,3.162-6.101,5.129c-63.904,18.867-110.559,77.923-110.559,147.927c0,1.072,0.079,2.124,0.097,3.177
c-0.085,4.709,0.072,9.35,0.514,13.926C372.585,455.434,358.065,471.09,358.065,490.254z M727.641,474.927
c7.099,1.858,12.388,7.931,12.388,15.327v12.603c0,8.104-6.27,14.743-14.368,15.878c0.769-6.2,1.271-12.464,1.271-18.849
c0-6.186-0.569-12.228-1.337-18.208C726.303,479.443,727.029,477.209,727.641,474.927z M420.723,479.509
c0.49-3.102,1.162-6.125,1.876-9.16c19.47-13.832,44.422-35.39,53.972-52.354c0,0,157.668,36.948,215.102,41.181
c1.646,0.125,3.207,0.191,4.702,0.203c2.113,6.549,3.807,13.218,4.897,20.129c1.053,6.67,1.761,13.436,1.761,20.377
c0,4.957-0.315,9.835-0.853,14.646c-0.908,8.261-2.657,16.241-5.035,23.984c-17.382,56.535-71.783,97.952-136.141,97.952
s-118.784-41.402-136.153-97.94c-2.385-7.741-4.127-15.735-5.023-23.978c-0.55-4.83-0.865-9.707-0.865-14.664
C418.961,492.945,419.669,486.178,420.723,479.509z M381.965,490.254c0-7.962,6.088-14.443,14.004-15.702
c0.048,3.068,0.157,6.088,0.224,9.157c-0.659,5.32-1.132,10.682-1.132,16.177c0,6.385,0.485,12.649,1.259,18.849
c-8.092-1.135-14.355-7.774-14.355-15.878V490.254z" />
      </g>
    </g>
  </svg>
</template>