<template>
    <div class="mb-2 block position-relative">
        <div class="loading-overlay" v-if="overlay"></div>
        <slot name="title">
            <div v-if="title" class="title">
                <h3>{{ title }}</h3>
            </div>
        </slot>
        <slot name="data"></slot>
        <slot name="toggle-button"></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        overlay: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.block {
    padding: 20px 25px 25px 25px;
}

.loading-overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 50px;
    z-index: 500;
}

.title {
    padding: 0 0 5px 20px;

    h3 {
        font-weight: 700;
        font-size: 22px;
    }
}

@media screen and (max-width: 1600px) {
    .title {
        h3 {
            font-size: 22px;
        }
    }
}
</style>